import React, { useState } from 'react';
import '../styles/ImageUploader.css';

function ImageUploader({ onStartGame }) {
    const [imagePreview, setImagePreview] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (ev) => {
                setImagePreview(ev.target.result);
                setImageSrc(ev.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleURLChange = (e) => {
        setImagePreview(e.target.value);
        setImageSrc(e.target.value);
    };

    const handleStartClick = () => {
        if (imageSrc) {
            onStartGame(imageSrc);
        } else {
            alert('Veuillez sélectionner une image ou entrer une URL valide.');
        }
    };

    return (
        <div className="upload-container">
            <h1>Bienvenue dans ton Exutoire !</h1>
            <p>
                Prêt à libérer ta frustration ? Choisis une image ci-dessous et prépare-toi à la dézinguer !
            </p>

            <div className="input-group">
                <label className="custom-file-upload">
                    <input type="file" accept="image/*" onChange={handleImageChange} />
                    Choisir une image
                </label>
                <span>Ou</span>
                <input
                    type="text"
                    placeholder="Entrez l'URL de l'image"
                    onChange={handleURLChange}
                />
            </div>

            {imagePreview && (
                <div className="preview">
                    <img src={imagePreview} alt="Aperçu" />
                </div>
            )}

            <button className="start-button" onClick={handleStartClick}>
                Let's Purge !
            </button>
        </div>
    );
}

export default ImageUploader;