import React, { useState } from 'react';
import ImageUploader from './components/ImageUploader';
import Game from './components/Game';
import './styles/App.css';

function App() {
  const [imageSrc, setImageSrc] = useState(null);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [settings, setSettings] = useState({
    speed: 3,
    spawnRate: 1500,
  });

  const handleStartGame = (src) => {
    setImageSrc(src);
    setIsGameStarted(true);
  };

  return (
    <div className="app-container">
      {!isGameStarted ? (
        <ImageUploader onStartGame={handleStartGame} />
      ) : (
        <Game
          imageSrc={imageSrc}
          settings={settings}
          setSettings={setSettings}
          onQuit={() => {
            setIsGameStarted(false);
            setImageSrc(null);
          }}
        />
      )}
    </div>
  );
}

export default App;