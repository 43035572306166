import React, { useState, useEffect, useCallback } from 'react';
import '../styles/Game.css';

function Game({ imageSrc, settings, setSettings, onQuit }) {
    const [score, setScore] = useState(0);
    const [bestScore, setBestScore] = useState(0);
    const [bubbles, setBubbles] = useState([]);
    const [comboCount, setComboCount] = useState(0);
    const [comboLevel, setComboLevel] = useState(1);
    const [floatingMessages, setFloatingMessages] = useState([]);
    const [nukeAvailable, setNukeAvailable] = useState(false);
    const [hoveredBubble, setHoveredBubble] = useState(null);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const savedBestScore = localStorage.getItem('bestScore');
        if (savedBestScore) {
            setBestScore(Number(savedBestScore));
        }
    }, []);

    useEffect(() => {
        if (score > bestScore) {
            setBestScore(score);
            localStorage.setItem('bestScore', score);
        }
    }, [score, bestScore]);

    useEffect(() => {
        let spawnInterval;
        let animationFrameId;

        const spawnBubble = () => {
            const id = Date.now() + Math.random();
            const speed = settings.speed;

            const speedX = (Math.random() - 0.5) * (Math.random() + 1) * speed;
            const speedY = (Math.random() - 0.5) * (Math.random() + 1) * speed;

            const bubble = {
                id,
                x: Math.random() * (window.innerWidth - 100),
                y: Math.random() * (window.innerHeight - 100),
                speedX,
                speedY,
                health: 3,
                spawnTime: Date.now(),
            };

            setBubbles((prev) => [...prev, bubble]);
        };

        spawnInterval = setInterval(spawnBubble, settings.spawnRate);

        const moveBubbles = () => {
            const currentTime = Date.now();
            setBubbles((prev) =>
                prev
                    .map((bubble) => ({
                        ...bubble,
                        x: bubble.x + bubble.speedX,
                        y: bubble.y + bubble.speedY,
                    }))
                    .filter(
                        (bubble) =>
                            bubble.x + 100 > 0 &&
                            bubble.x < window.innerWidth &&
                            bubble.y + 100 > 0 &&
                            bubble.y < window.innerHeight &&
                            currentTime - bubble.spawnTime < 60000
                    )
            );

            animationFrameId = requestAnimationFrame(moveBubbles);

        };

        animationFrameId = requestAnimationFrame(moveBubbles);

        return () => {
            clearInterval(spawnInterval);
            cancelAnimationFrame(animationFrameId);
        };
    }, [settings]);

    const bubbleDestroyed = useCallback((x, y) => {
        setComboCount((prev) => prev + 1);

        if ((comboCount + 1) % 10 === 0 && comboLevel < 5) {
            setComboLevel((prev) => prev + 1);
        }

        if ((comboCount + 1) % 10 === 0 && comboLevel === 5) {
            setNukeAvailable(true);
        }

        const scoreIncrement = 1 * comboLevel;
        setScore((prev) => prev + scoreIncrement);

        const phrases = [
            'Prends ça !', 'Dans ta face !', 'Boom !', 'Hit !', 'Touché !',
            'Ça, c’est du lourd !', 'Mange ça !', 'Massacre !', 'Dans les dents !',
            'Pulvérisé !', 'Explosé !', 'Écrasé !', 'Pas de pitié !', 'Au tapis !'
        ];
        const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)];

        const messageText = Math.random() < 0.2 ? randomPhrase : `+${scoreIncrement}`;

        let comboText = '';
        if (comboLevel > 1) {
            comboText = `Combo x${comboLevel}`;
        }

        const messageId = Date.now() + Math.random();

        setFloatingMessages((prev) => [
            ...prev,
            {
                id: messageId,
                type: "hit",
                x,
                y,
                text: messageText,
                comboText,
            },
        ]);

        setTimeout(() => {
            setFloatingMessages((prev) =>
                prev.filter((message) => message.id !== messageId)
            );
        }, 1000);
    }, [comboCount, comboLevel]);

    const popBubble = useCallback((id) => {
        setBubbles((prevBubbles) =>
            prevBubbles.map((bubble) => {
                if (bubble.id === id) {
                    const newHealth = bubble.health - 1;
                    if (newHealth > 0) {
                        return { ...bubble, health: newHealth };
                    } else {
                        bubbleDestroyed(bubble.x, bubble.y);
                        setHoveredBubble(null);
                        return null;
                    }
                }
                return bubble;
            }).filter(Boolean)
        );
    }, [bubbleDestroyed]);

    const missAction = useCallback((x, y) => {
        setComboCount(0);
        setComboLevel(1);
        setNukeAvailable(false);

        const scoreIncrement = 5;

        setScore((prev) => Math.max(0, prev - scoreIncrement));

        const phrases = [
            'Oups...', 'Non...', 'Raté...'
        ];
        const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)];

        const messageText = Math.random() < 0.2 ? randomPhrase : `-${scoreIncrement}`;

        const messageId = Date.now() + Math.random();

        setFloatingMessages((prev) => [
            ...prev,
            {
                id: messageId,
                type: "miss",
                x,
                y,
                text: messageText,
                comboText: '',
            },
        ]);

        setTimeout(() => {
            setFloatingMessages((prev) =>
                prev.filter((message) => message.id !== messageId)
            );
        }, 1000);
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            setCursorPosition({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [setCursorPosition]);

    useEffect(() => {
        const handleKeydown = (e) => {
            if (e.code === 'Space') {
                if (hoveredBubble !== null) {
                    popBubble(hoveredBubble.id);
                } else {
                    missAction(cursorPosition.x, cursorPosition.y);
                }
            }
        };

        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [hoveredBubble, popBubble, cursorPosition, missAction]);

    const handleMiss = (e) => {
        if (e.target.className === 'game-container') {
            missAction(e.clientX, e.clientY);
        }
    };

    const handleSettingsChange = (e) => {
        const { name, value } = e.target;
        setSettings((prev) => ({
            ...prev,
            [name]: Number(value),
        }));
    };

    const activateNuke = () => {
        setScore((prev) => prev + 100);
        setBubbles([]);
        setNukeAvailable(false);
        setComboCount(0);
        setComboLevel(1);

        const messageId = Date.now() + Math.random();

        setFloatingMessages((prev) => [
            ...prev,
            {
                id: messageId,
                type: "nuke",
                x: window.innerWidth / 2,
                y: window.innerHeight / 2,
                text: 'PURGE TOTALE ! +100',
                comboText: 'MAXIMUM COMBO !!!',
            },
        ]);

        setTimeout(() => {
            setFloatingMessages((prev) =>
                prev.filter((message) => message.id !== messageId)
            );
        }, 1000);
    };

    return (
        <div className="game-container" onClick={handleMiss}>
            {bubbles.map((bubble) => (
                <div
                    key={bubble.id}
                    className="bubble-container"
                    style={{
                        left: bubble.x,
                        top: bubble.y,
                    }}
                >
                    <img
                        src={imageSrc}
                        alt="Bubble"
                        className="image-bubble"
                        draggable="false"
                        onMouseEnter={() => setHoveredBubble(bubble)}
                        onMouseLeave={() => setHoveredBubble(null)}
                        onClick={(e) => {
                            e.stopPropagation();
                            popBubble(bubble.id);
                        }}
                        onDragStart={(e) => e.preventDefault()}
                    />
                    <div className="health-bar">
                        <div
                            className="health-bar-inner"
                            style={{ width: `${(bubble.health / 3) * 100}%` }}
                        ></div>
                    </div>
                </div>
            ))}

            {floatingMessages.map((msg) => (
                <div
                    key={msg.id}
                    className={`floating-message ${msg.type}`}
                    style={{
                        left: msg.x,
                        top: msg.y,
                    }}
                >
                    <div>{msg.text}</div>
                    {msg.comboText && <div>{msg.comboText}</div>}
                </div>
            ))}

            {nukeAvailable && (
                <button className="nuke-button" onClick={activateNuke}>
                    NUKE
                </button>
            )}

            <div className="game-hud">
                <button className="quit-button" onClick={onQuit}>
                    Alors, détendu ?
                </button>
                <div className="score-container">
                    Score : {score} <br />
                    Meilleur score : {bestScore}
                </div>
            </div>

            <div className="settings-panel">
                <div className="setting">
                    <label>Vitesse :</label>
                    <input
                        type="range"
                        name="speed"
                        min="1"
                        max="6"
                        value={settings.speed}
                        onChange={handleSettingsChange}
                    />
                    <span>{settings.speed}</span>
                </div>
                <div className="setting">
                    <label>Fréquence d'apparition :</label>
                    <input
                        type="range"
                        name="spawnRate"
                        min="100"
                        max="3000"
                        step="100"
                        value={settings.spawnRate}
                        onChange={handleSettingsChange}
                    />
                    <span>{settings.spawnRate / 1000} s</span>
                </div>
            </div>
        </div>
    );
}

export default Game;